import React, { useState } from "react";
import chiesa from "../../Assets/foto/cartoline/chiesa.jpeg";


function Storia() {
	const [PDF, setPDF] = useState();
	const [first, setFirst] = useState(false);

	return (
		<div className="flex flex-col justify-center">
			<h1 className="place-self-center text-4xl md:text-6xl font-semibold my-4">
				STORIA DI MONDRONE
			</h1>
			<div className="grid grid-cols-1 md:grid-cols-2">
				{" "}
				<div className="flex flex-col px-12  place-self-center text-justify">
					<p className="my-2">
						Mondrone, situato tra i Comuni di Ala di Stura e di Balme, è un
						piccolo borgo costituito da due frazioni “Colomba” e “Codera”,
						separate dal Rio delle Combette.
					</p>
					<p className="my-2">
						In origine aveva nome Mondreono, Mondarono, Montdreon, forse dal
						nome del monte che lo sovrasta, anticamente chiamato Mons Dreonis e
						oggi noto come Uja di Mondrone.
					</p>
					<p className="my-2">
						I primi insediamenti, formati da pochi casolari situati più in alto
						del paese attuale risalgono all’anno 1000 mentre si ritiene che la
						collocazione attuale del paese risalga al 1300.
					</p>
					<p className="my-2">
						Sino al 1621 Mondrone fu frazione del comune di Ala di Stura, poi si
						rese indipendente e tale restò fino a quando nel 1928 fu nuovamente
						aggregato al comune di Ala. Nello stesso periodo anche la
						parrocchia, indipendente dal 1620, fu assorbita dalla parrocchia di
						Ala di Stura.
					</p>
					<p className="my-2">
						Una narrazione delle origini di Mondrone dal 1200 è contenuta nel
						libro “Mondrone, seicento anni di storia” pubblicato nel 1992 a cura
						del Circolo Mondronese.
					</p>
					<p className="my-2">
						La pubblicazione, a partire dal testo “Storia onomastica della Valle
						di Lanzo” scritta dal mondronese Don Silvio Solero nel 1954-55,
						raccoglie una ricostruzione della storia più antica della valle, una
						descrizione dei principali lignaggi (cognomi) di Mondrone e un’ampia
						sezione dedicata alla vita sociale animata dalle iniziative del
						Circolo Mondronese dalle origini del Circolo nel 1968, anno di
						stesura dello Statuto, sino al 2009.
					</p>
					<p className="my-2">
						Per approfondimenti{" "}
						<span
							className="hover:underline text-blue-500 cursor-pointer ml-1"
							onClick={() => {
								setPDF(
                                    "/PDF/Libro-Mondrone-2009.pdf"
                                );
								setFirst(!first);
							}}
						>
							{" "}
							“Mondrone, seicento anni di storia”
						</span>
					</p>
					<p className="my-2">
						Quanto riprodotto nella presente sezione è tutelato secondo le norme
						vigenti sul diritto d’autore. Per qualsiasi uso si invita a
						contattare{" "}
						<a
							className="text-blue-500 hover:underline"
							href="mailto:direttivo@mondrone.it"
						>
							direttivo@mondrone.it
						</a>
					</p>
				</div>
				<div className="w-full">
					<img
						className={`w-full object-contain ${first ? "hidden" : "block"}`}
						src={chiesa}
						alt=""
					/>
					<div className={`w-full h-full ${first ? "block" : "hidden"}`}>
						<object
							data={PDF}
							type="application/pdf"
							width="100%"
							height="100%"
							className="max-w-[70rem] h-full"
						>
							<p className="flex  px-12">
								<p>
									Clicca qui per vedere il <a href={PDF}>PDF!</a>,<br /> oppure{" "}
									<button
										className="justify-self-end underline"
										onClick={() => setFirst(!first)}
									>
										torna all'immagine
									</button>.
								</p>
							</p>
						</object>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Storia;
